<template>
    <div class="login">
        <div class="texture">
            <div class="login_wrapper">
                <div class="login_logo">
                    <img src="../assets/images/login_logo.png" alt="logo">
                </div>
                <div class="login_text">V猎人才管理系统</div>
                <div class="login_form">
                    <h1>用户登录</h1>
                    <Form ref="user" :model="user" :rules="ruleInline">
                        <FormItem prop="username">
                            <Input type="text" size="large" v-model.trim="user.username" placeholder="账号"
                                    @keyup.enter.native="handleLogin">
                                <Icon type="ios-person" slot="prefix"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem prop="password">
                            <Input type="password" size="large" v-model="user.password" clearable placeholder="密码"
                                    @keyup.enter.native="handleLogin">
                                <Icon type="ios-lock" slot="prefix"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem prop="captcha">
                            <Input class="captcha" type="text" size="large" prop="imgCode" maxlength="4"
                                    v-model="user.captcha" placeholder="请输入验证码" @keyup.enter.native="handleLogin">
                                <template slot="append">
                                    <img class="img_code" :src="myCodeImg" @click="showCodeImg"/>
                                </template>
                            </Input>
                        </FormItem>
                        <FormItem class="checkbox">
                            <Checkbox v-model="checked">记住用户名</Checkbox>
                            <Tooltip content="请联系系统管理员" placement="top-end">
                                忘记密码？
                            </Tooltip>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" size="large" @click.native.prevent="handleLogin"
                                    :loading="loading"
                                    long>登录
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
            <div class="copyright">
                <p>Copyright &copy; {{ (new Date).getFullYear() }} 西安微聘信息科技有限公司</p>
            </div>
        </div>
    </div>
</template>

<script>
import {reqSystemLogin, reqCodeImg} from '../api/system-api';
import {encode64} from '../assets/js/utils';

export default {
    name: "login",
    data() {
        return {
            loading: false,
            user: {
                username: '',
                password: '',
                captcha: ''
            },
            myCodeImg: null,
            captchaImage: '',
            ruleInline: {
                username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                captcha: [{required: true, message: '请输入验证码', trigger: 'blur'}]
            },
            checked: true
        }
    },
    methods: {
        //记住用户
        setCookie(username, exdays) {
            let exdate = new Date()
            exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
            // 字符串拼接cookie
            window.document.cookie = 'username' + '=' + username + ';path=/;expires=' + exdate.toGMTString()
        },
        // 读取cookie
        getCookie() {
            document.cookie?.split(';').map(item => {
                if (item.startsWith('username')) {
                    this.user.username = item.split('=')[1];
                }
            })
        },
        // 清除cookie
        clearCookie() {
            this.setCookie('', -1);
        },
        //登录
        handleLogin() {
            this.$refs.user.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    let password = encode64(encode64(this.user.password) + 'pd');
                    let username = encode64(encode64(this.user.username) + 'un');
                    let loginParams = {
                        username: username,
                        password: password,
                        captcha: this.user.captcha,
                        captchaImage: this.captchaImage
                    };
                    reqSystemLogin(loginParams).then(data => {
                        this.loading = false;
                        if (data.code === 1) {
                            this.$Message.success(data.msg);
                            sessionStorage.setItem('un', this.user.username);
                            sessionStorage.setItem('Authorization', data.data.token);
                            if (this.checked == true) {
                                this.setCookie(this.user.username, 7);
                            } else {
                                this.clearCookie();
                            }
                            this.$router.push({path: '/'});
                        } else if (data.code === 8) {
                            this.$Message.success(data.msg);
                            sessionStorage.setItem('un', this.user.username);
                            sessionStorage.setItem('Authorization', data.data.token);
                            if (this.checked == true) {
                                this.setCookie(this.user.username, 7);
                            } else {
                                this.clearCookie();
                            }
                            this.$router.push({path: '/Unauthorized'});
                        } else {
                            this.user.captcha = '';
                            this.$Message.error(data.msg);
                            this.showCodeImg();
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.user.captcha = '';
                        this.showCodeImg();
                        this.$Message.error('登录异常');
                    })
                }
            });
        },

        //展示验证码
        showCodeImg() {
            let that = this;
            reqCodeImg().then((res) => {
                that.myCodeImg = window.URL.createObjectURL(new Blob([res.data]));
                that.captchaImage = res.headers['captcha_key'];
            }).catch(() => {
                that.myCodeImg = null;
                that.captchaImage = "";
                this.$Message.error('获取验证码失败');
            });
        }
    },
    mounted() {
        this.getCookie();
        this.showCodeImg();
    }
}
</script>

<style lang="less">
// Related style
@import '../index.less';

.login {
    height: 100%;
    background-color: #57627C;
    /* background-image: linear-gradient( 135deg, #FFFFFF 2%, #32c9ca 100%); */
    /* background-image: radial-gradient(farthest-corner at 50% 35%, #D8F5F5, #32c9ca); */

    .texture {
        height: 100%;
        background-image: url(../assets/images/login-bg.jpg);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        .login_wrapper {
            width: 480px;
            margin: 0 auto;
            padding-top: 8%;

            .login_logo {
                text-align: center;
            }

            .login_text {
                color: #fff;
                font-size: 22px;
                font-weight: 600;
                text-align: center;
            }

            .login_form {
                margin: 20px 60px 0;
                padding: 30px;
                background-color: @component-background;
                border-radius: 10px;
                box-shadow: rgba(117, 149, 178, 0.24) 0px 0px 24px 0px;

                h1 {
                    padding-bottom: 20px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .ivu-form {

                    .ivu-form-item {
                        .ivu-form-item-content {
                            .ivu-tag {
                                position: absolute;
                                right: 5px;
                                top: 7px;
                            }

                            .captcha {
                                .ivu-input {
                                    padding-left: 32px;
                                    background-image: url(../assets/images/input_security.png);
                                    background-position: 7px center;
                                    background-repeat: no-repeat;
                                    background-size: 17px 17px;
                                }

                                .ivu-input-group-append {
                                    padding: 0;
                                    background-color: #FFEDBB;

                                    img.img_code {
                                        display: inline-block;
                                        height: 31px;
                                        margin-top: 5px;
                                    }
                                }
                            }

                            .ivu-tooltip {
                                float: right;
                                color: @primary-color;
                            }
                        }
                    }

                    .checkbox.ivu-form-item {
                        margin-bottom: 8px;
                    }

                    .ivu-divider {
                        margin-top: 130px;
                        margin-bottom: 10px;
                    }

                    .tips {
                        color: #999;
                    }

                    .ivu-form-item {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                }
            }
        }

        .copyright {
            display: block;
            width: 100%;
            margin-top: 20px;
            text-align: center;
            color: @text-color-secondary;
            line-height: 20px;
        }
    }
}
</style>
